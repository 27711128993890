import React from 'react';
import List from '@mui/material/List';

// import routesConfig from '../../../../../pages/routesConfig';
import NavVerticalGroup from './VerticalNavGroup';
import VerticalCollapse from './VerticalCollapse';
import VerticalItem from './VerticalItem';
import {useAuthUser} from '@crema/utility/AuthHooks';

const VerticalNav = () => {
  const {user} = useAuthUser();

  return (
    <List
      sx={{
        position: 'relative',
        padding: 0,
      }}
      component='div'
    >
      {user.menus
        ?.filter((item) => {
          if (Array.isArray(item.children) && item.type === 'collapse') {
            return item.children.length;
          }

          return true;
        })
        .map((item) => (
          <React.Fragment key={item.id}>
            {item.type === 'group' && (
              <NavVerticalGroup item={item} level={0} />
            )}

            {item.type === 'collapse' && (
              <VerticalCollapse item={item} level={0} />
            )}

            {item.type === 'item' && <VerticalItem item={item} level={0} />}
          </React.Fragment>
        ))}
    </List>
  );
};

export default VerticalNav;
