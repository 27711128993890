import axios from 'services/axios';
import {useMutation, useQueryClient} from 'react-query';
import {QUERY} from './useQuery';
import standAloneAxios from 'axios';
import CONFIG from 'config/env';

export function useLogin() {
  const queryClient = useQueryClient();

  return useMutation(
    (values) =>
      axios.post('api/v1/admin/auth/login', values).then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY);
        queryClient.removeQueries(QUERY);
      },
    },
  );
}

export function useForgotPassword() {
  const queryClient = useQueryClient();

  return useMutation(
    (values) =>
      axios
        .post('api/v1/admin/auth/forgot_password', values)
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY);
        queryClient.removeQueries(QUERY);
      },
    },
  );
}

export function useResetPassword() {
  const queryClient = useQueryClient();
  const BASE_URL = CONFIG.endpoint.API_ENDPOINT;

  return useMutation(
    (values) => {
      const {token} = values;
      delete values.token;

      return standAloneAxios
        .put(`${BASE_URL}api/v1/admin/auth/reset_password`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => res.data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY);
        queryClient.removeQueries(QUERY);
      },
    },
  );
}

export function useChangePassword() {
  const queryClient = useQueryClient();

  return useMutation(
    (values) =>
      axios
        .put('api/v1/admin/auth/change_password', values)
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY);
        queryClient.removeQueries(QUERY);
      },
    },
  );
}

export function useChangeProfile() {
  const queryClient = useQueryClient();

  return useMutation(
    (values) =>
      axios.put('api/v1/admin/auth/profile', values).then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY);
        queryClient.removeQueries(QUERY);
      },
    },
  );
}
