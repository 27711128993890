import React from 'react';

const List = React.lazy(() => import('./List'));

export const receivablesReportPerSIPagesConfigs = [
  {
    path: '/receivables-report-per-si',
    element: <List />,
  },
];
