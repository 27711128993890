const getInitialUrl = (menus = [], index = 0) => {
  let result = menus[index]?.url;

  if (menus.length > index) {
    if (!result) {
      if (
        Array.isArray(menus[index].children) &&
        menus[index]?.children.length
      ) {
        const childResult = getInitialUrl(menus[index].children);

        if (childResult) {
          return childResult;
        }
      }
      return getInitialUrl(menus, index + 1);
    }
  } else if (!menus[index]?.children?.length) {
    return '/dashboard';
  }

  return result;
};

export default getInitialUrl;
