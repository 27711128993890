export function isSubPath(currentPath, subPath) {
  // Memastikan bahwa kedua path dimulai dengan '/'
  if (!currentPath.startsWith('/') || !subPath?.startsWith('/')) {
    console.error("Both paths should start with '/'");
    return false;
  }

  // Mengubah path menjadi array yang berisi setiap bagian path
  const currentPathArray = currentPath.split('/');
  const subPathArray = subPath?.split('/');

  // Memeriksa setiap bagian path
  for (let i = 1; i < subPathArray.length; i++) {
    // Jika ada perbedaan pada bagian path, maka tidak merupakan subpath
    if (currentPathArray[i] !== subPathArray[i]) {
      return false;
    }
  }

  // Jika loop selesai tanpa mengembalikan false, berarti merupakan subpath
  return true;
}
