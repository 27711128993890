import axios from 'services/axios';
import {useMutation, useQueryClient} from 'react-query';
import {QUERY} from './useQuery';

export function useAddBillingReport() {
  const queryClient = useQueryClient();

  return useMutation(
    (values) =>
      axios
        .post(`api/v2/admin/bill`, {
          ...values,
          id: undefined,
          truckingId: undefined,
        })
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY);
        queryClient.removeQueries(QUERY);
      },
    },
  );
}

export function useEditBillingReport() {
  const queryClient = useQueryClient();

  return useMutation(
    (formData) => {
      const {id} = formData;
      delete formData.id;
      return axios
        .put(`api/v2/admin/bill/${id}`, formData)
        .then((res) => res.data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY);
        queryClient.removeQueries(QUERY);
      },
    },
  );
}

export function useDeleteBillingReport() {
  const queryClient = useQueryClient();

  return useMutation(
    ({id}) => axios.delete(`api/v2/admin/bill/${id}`).then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY);
        queryClient.removeQueries(QUERY);
      },
    },
  );
}

export function useGenerateBillingReport() {
  const queryClient = useQueryClient();

  return useMutation(
    (values) =>
      axios
        .post(`api/v2/admin/bill`, {
          ...values,
        })
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY);
        queryClient.removeQueries(QUERY);
      },
    },
  );
}

export function useMergeBillingReport() {
  const queryClient = useQueryClient();

  return useMutation(
    (values) =>
      axios
        .post(`api/v2/admin/bill/merge`, {
          ...values,
        })
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY);
        queryClient.removeQueries(QUERY);
      },
    },
  );
}

export function useSubmitInvoiceBillingReport() {
  const queryClient = useQueryClient();

  return useMutation(
    (values) =>
      axios
        .post(`api/v2/admin/invoice`, {
          ...values,
        })
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY);
        queryClient.removeQueries(QUERY);
        queryClient.invalidateQueries('list-bill-si');
        queryClient.removeQueries('list-bill-si');
        queryClient.invalidateQueries('ship-departure');
        queryClient.removeQueries('ship-departure');
      },
    },
  );
}

export function useSplitInvoiceBillingReport(id) {
  const queryClient = useQueryClient();

  return useMutation(
    (values) =>
      axios
        .post(`api/v2/admin/bill/${id}/split`, {
          ...values,
        })
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY);
        queryClient.removeQueries(QUERY);
      },
    },
  );
}
