import {useState} from 'react';
import {useQuery} from 'react-query';
import axios from 'services/axios';

export const QUERY = 'receivables-report';

export async function getReceivablesReports(filter) {
  const {data} = await axios.get(`api/v2/admin/laporan-piutang`, {
    params: {...filter},
  });

  return data;
}

export function useReceivablesReport(defaultFilter = {}) {
  const [filter, setFilter] = useState({
    page: 1,
    search: null,
    // sortBy: 'desc',
    // sortOrder: 'created_at',
    limit: 10,
  });
  const fallback = [];
  const {
    data = fallback,
    isLoading,
    isError,
    error,
  } = useQuery([QUERY, filter, defaultFilter], async () =>
    getReceivablesReports({...filter, ...defaultFilter}),
  );

  return {
    data,
    isLoading,
    isError,
    error,
    filter,
    setFilter,
  };
}

export const fetchReceivablesReport = (receivablesReportId) => {
  return axios
    .get(`api/v1/admin/laporan-piutang/${receivablesReportId}`)
    .then((res) => res.data);
};

export function useDetailReceivablesReport(receivablesReportId) {
  return useQuery({
    queryKey: receivablesReportId && [QUERY, receivablesReportId],
    queryFn: () => fetchReceivablesReport(receivablesReportId),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: !!receivablesReportId,
  });
}

export async function getReceivablesReportInvoicies(filter) {
  const {data} = await axios.get(`api/v2/admin/invoice`, {
    params: {...filter},
  });

  return data;
}

export function useReceivablesReportInvoice(defaultFilter = {}) {
  const [filter, setFilter] = useState({
    page: 1,
    search: null,
    // sortBy: 'desc',
    // sortOrder: 'created_at',
    limit: 10,
  });
  const fallback = [];
  const {
    data = fallback,
    isLoading,
    isError,
    error,
  } = useQuery(
    [QUERY, filter, 'invoice', defaultFilter],
    async () => getReceivablesReportInvoicies({...filter, ...defaultFilter}),
    {
      refetchOnMount: true,
    },
  );

  return {
    data,
    isLoading,
    isError,
    error,
    filter,
    setFilter,
  };
}

export const fetchReceivablesReportInvoice = (invoiceId) => {
  return axios.get(`api/v2/admin/invoice/${invoiceId}`).then((res) => res.data);
};

export function useDetailReceivablesReportInvoice(invoiceId) {
  return useQuery({
    queryKey: invoiceId && [QUERY, invoiceId],
    queryFn: () => fetchReceivablesReportInvoice(invoiceId),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: !!invoiceId,
  });
}

export const fetchReceivablesReportByCustomer = (customer_id) => {
  return axios
    .get(`api/v1/admin/laporan-piutang`, {
      params: {customer_id},
    })
    .then((res) => res.data);
};

export function useDetailReceivablesReportByCustomer(customerId) {
  return useQuery({
    queryKey: customerId && [QUERY, customerId],
    queryFn: () => fetchReceivablesReportByCustomer(customerId),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: !!customerId,
  });
}

export async function getInvoiceEvidences(filter) {
  const {data} = await axios.get(`api/v1/admin/invoice_evidence`, {
    params: {...filter},
  });

  return data;
}

export function useInvoiceEvidence(defaultFilter = {}) {
  const [filter, setFilter] = useState({
    page: 1,
    search: null,
    sortBy: 'desc',
    sortOrder: 'created_at',
    limit: 10,
  });
  const fallback = [];
  const {
    data = fallback,
    isLoading,
    isError,
    error,
  } = useQuery(
    [QUERY, filter, 'invoice-evidence'],
    async () => getInvoiceEvidences({...filter, ...defaultFilter}),
    {
      refetchOnMount: true,
    },
  );

  return {
    data,
    isLoading,
    isError,
    error,
    filter,
    setFilter,
  };
}

export async function downloadInvoices(filter) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/api/v2/admin/invoice/excel`, {
        params: {...filter},
        responseType: 'arraybuffer',
        headers: {
          Accept: 'application/octet-stream',
        },
      })
      .then((response) => {
        const blob = new Blob(
          [response.data],
          {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
          }, //For excel file
        );

        const aEle = document.createElement('a'); // Create a label
        const href = window.URL.createObjectURL(blob); // Create downloaded link

        aEle.href = href;
        aEle.download = 'invoices.xlsx'; // File name after download
        document.body.appendChild(aEle);
        aEle.click(); // Click to download
        document.body.removeChild(aEle); // Download complete remove element
        window.URL.revokeObjectURL(href); // Release blob object

        resolve(response);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
}

export const fetchInvoiceEvidence = (invoiceEvidenceId) => {
  return axios
    .get(`api/v1/admin/invoice_evidence/${invoiceEvidenceId}`)
    .then((res) => res.data);
};

export function useDetailInvoiceEvidence(invoiceEvidenceId) {
  return useQuery({
    queryKey: invoiceEvidenceId && [
      QUERY,
      invoiceEvidenceId,
      'invoice-evidence',
    ],
    queryFn: () => fetchInvoiceEvidence(invoiceEvidenceId),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: !!invoiceEvidenceId,
  });
}

export async function downloadPiutangById(id) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`api/v2/admin/invoice/${id}/print`, {
        responseType: 'arraybuffer',
        headers: {
          Accept: 'application/octet-stream',
        },
      })
      .then((response) => {
        const blob = new Blob(
          [response.data],
          {
            type: 'application/pdf',
          }, //For excel file
        );

        const aEle = document.createElement('a'); // Create a label
        const href = window.URL.createObjectURL(blob); // Create downloaded link

        aEle.href = href;
        aEle.download = 'piutang.pdf'; // File name after download
        document.body.appendChild(aEle);
        aEle.click(); // Click to download
        document.body.removeChild(aEle); // Download complete remove element
        window.URL.revokeObjectURL(href); // Release blob object

        resolve(response);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
}

export async function getReceivablesReportsPerSI(filter) {
  const {data} = await axios.get(`api/v1/admin/laporan-piutang-si`, {
    params: {...filter},
  });

  return data;
}

export function useReceivablesReportPerSI(defaultFilter = {}) {
  const [filter, setFilter] = useState({
    page: 1,
    search: null,
    // sortBy: 'desc',
    // sortOrder: 'created_at',
    limit: 10,
  });
  const fallback = [];
  const {
    data = fallback,
    isLoading,
    isError,
    error,
  } = useQuery([QUERY, filter, defaultFilter, 'per-si'], async () =>
    getReceivablesReportsPerSI({...filter, ...defaultFilter}),
  );

  return {
    data,
    isLoading,
    isError,
    error,
    filter,
    setFilter,
  };
}

export async function downloadReceivablesReportPerSI(filter) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`api/v1/admin/laporan-piutang-si/download`, {
        params: {...filter},
        responseType: 'arraybuffer',
        headers: {
          Accept: 'application/octet-stream',
        },
      })
      .then((response) => {
        const blob = new Blob(
          [response.data],
          {
            // type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
            type: 'application/pdf',
          }, //For excel file
        );

        const aEle = document.createElement('a'); // Create a label
        const href = window.URL.createObjectURL(blob); // Create downloaded link

        aEle.href = href;
        aEle.download = 'laporan-piutang-per-si.pdf'; // File name after download
        document.body.appendChild(aEle);
        aEle.click(); // Click to download
        document.body.removeChild(aEle); // Download complete remove element
        window.URL.revokeObjectURL(href); // Release blob object

        resolve(response);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
}
