import {authRouteConfig} from './auth';
import {initialUrl} from 'shared/constants/AppConst';
import {Navigate} from 'react-router-dom';
import Error403 from './errorPages/Error403';
import React from 'react';
import {errorPagesConfigs} from './errorPages';
import {accountPagesConfigs} from './account';
import {adminManagementPagesConfigs} from './AdminManagement';
import {roleManagementPagesConfigs} from './RoleManagement';
import {commodityManagementPagesConfigs} from './CommodityManagement';
import {vendorTruckingManagementPagesConfigs} from './VendorTruckingManagement';
import {routeManagementPagesConfigs} from './RouteManagement';
import {customerManagementPagesConfigs} from './CustomerManagement';
import {vendorShipManagementPagesConfigs} from './VendorShipManagement';
import {shippingManagementPagesConfigs} from './ShippingManagement';
import {portPricePagesConfigs} from './PortPrice';
import {shippingPricePagesConfigs} from './ShippingPrice';
import {otherPricePagesConfigs} from './OtherPrice';
import {lclPagesConfigs} from './LCL';
import {fclPagesConfigs} from './FCL';
import {orderManagementPagesConfigs} from './OrderManagement';
import {shipDepartPagesConfigs} from './ShipDeparture';
import {billingReportPagesConfigs} from './BillingReport';
import {receivablesReportPagesConfigs} from './ReceivablesReport';
import {claimManagementPagesConfigs} from './ClaimManagement';
import {ticketManagementPagesConfigs} from './Ticket';
import {goodsReceiptPagesConfigs} from './GoodsReceipt';
import {ttbPagesConfigs} from './Ttb';
import {dashboardPagesConfigs} from './Dashboard';
import {hppTermPagesConfigs} from './HppTerm';
import {cityManagementPagesConfigs} from './City';
import {districtManagementPagesConfigs} from './District';
import {portManagementPagesConfigs} from './Port';
import {receivablesReportPerSIPagesConfigs} from './ReceivablesReportPerSI';
import InitPage from './InitPage';

const authorizedStructure = {
  fallbackPath: '/signin',
  unAuthorizedComponent: <Error403 />,
  routes: [
    ...accountPagesConfigs,
    ...adminManagementPagesConfigs,
    ...shippingManagementPagesConfigs,
    ...customerManagementPagesConfigs,
    ...roleManagementPagesConfigs,
    ...commodityManagementPagesConfigs,
    ...vendorTruckingManagementPagesConfigs,
    ...vendorShipManagementPagesConfigs,
    ...routeManagementPagesConfigs,
    ...portPricePagesConfigs,
    ...shippingPricePagesConfigs,
    ...otherPricePagesConfigs,
    ...lclPagesConfigs,
    ...fclPagesConfigs,
    ...orderManagementPagesConfigs,
    ...shipDepartPagesConfigs,
    ...billingReportPagesConfigs,
    ...receivablesReportPagesConfigs,
    ...claimManagementPagesConfigs,
    ...ticketManagementPagesConfigs,
    ...goodsReceiptPagesConfigs,
    ...ttbPagesConfigs,
    ...dashboardPagesConfigs,
    ...hppTermPagesConfigs,
    ...cityManagementPagesConfigs,
    ...districtManagementPagesConfigs,
    ...portManagementPagesConfigs,
    ...receivablesReportPerSIPagesConfigs,
  ],
};

const unAuthorizedStructure = {
  fallbackPath: initialUrl,
  routes: authRouteConfig,
};

const anonymousStructure = {
  routes: errorPagesConfigs.concat([
    {
      path: '/',
      // element: <Navigate to={initialUrl} />,
      element: <InitPage />,
    },
    {
      path: '*',
      element: <Navigate to='/error-pages/error-404' />,
    },
  ]),
};

export {authorizedStructure, unAuthorizedStructure, anonymousStructure};
